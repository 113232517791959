import cn from 'classnames';
import styles from './AdsBlock.module.scss';
import { AdType } from '@/types';
import { AdsCard } from '../AdsCard';
import { useEffect, useState } from 'react';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { TABLET_RESOLUTION } from '@/constants/constants';

type AdsBlockProps = {
  ads: AdType[];
  className?: string;
};

export function AdsBlock({ ads, className }: AdsBlockProps) {
  const [adsArr, setAdsArr] = useState(ads);
  const [prevWidth, setPrevWidth] = useState<'desktop' | 'tablet'>('desktop');
  const width = useWindowWidth();

  useEffect(() => {
    if (ads.length > 1 && width && width > TABLET_RESOLUTION) {
      if (prevWidth === 'desktop') return;
      setPrevWidth('desktop');
      const items = ads.sort(() => Math.random() - Math.random()).slice(0, 2);
      setAdsArr(items);
    }
    if (ads.length > 1 && width && width < TABLET_RESOLUTION) {
      if (prevWidth === 'tablet') return;
      setPrevWidth('tablet');
      const items = ads.sort(() => Math.random() - Math.random()).slice(0, 1);
      setAdsArr(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, ads]);

  const adsClassName = cn(styles.ads, className);

  const wrapperClassName = cn(styles.wrapper, {
    [styles.doubleHeight]: ads.length > 1,
  });

  if (!adsArr || adsArr.length === 0) return null;

  return (
    <div className={wrapperClassName}>
      <div className={adsClassName}>
        <p className={styles.heading}>РЕКЛАМА</p>
        <div className={styles.row}>
          {adsArr.map((ad) => (
            <AdsCard
              key={ad.id}
              title={ad.name}
              description={ad.text}
              img={ad.picture}
              href={ad.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
